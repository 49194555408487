import React, { useEffect, useState } from 'react';
import Preloader from '../../Components/Preloader';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Contact_Us_Seo from '../../SEO/Contact_Us_Seo';

function Contact_Us() {
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({ name: '', email: '', phoneno: '', msg: '' });
    const [errors, setErrors] = useState({});
    const [captchaToken, setCaptchaToken] = useState('');

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name) {
            formErrors.name = 'Name is required';
        } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
            formErrors.name = 'Name can only contain letters and spaces';
        }
        if (!formData.email) {
            formErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = 'Email address is invalid';
        }
        if (!formData.phoneno) {
            formErrors.phoneno = 'Phone number is required';
        } else if (!/^\d{10,15}$/.test(formData.phoneno)) {
            formErrors.phoneno = 'Phone number is invalid';
        }
        if (!formData.msg) {
            formErrors.msg = 'Message is required';
        }
        if (!captchaToken) {
            formErrors.captcha = 'Captcha is required';
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await axios.post('/api/contact', { ...formData, captchaToken });
                console.log('Form submitted successfully:', response.data);
                setFormData({ name: '', email: '', phoneno: '', msg: '' });
                setCaptchaToken('');
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                <div>
                    <Contact_Us_Seo/>
                    <Header />
                    <section className="p-5" style={{ backgroundImage: 'url(assets/img/hero-three.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-end">
                                <div className="col-lg-6">
                                    <div className="bannr-text">
                                        <h2>Contact Us</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="gap meet-section">
                        <div className="container meet-section-before">
                            <div className="row align-items-center pl-80">
                                <div className="col-lg-4 col-md-6">
                                    <div className="location">
                                        <i>
                                            <svg height={112} viewBox="0 0 24 24" width={112} xmlns="http://www.w3.org/2000/svg"><g clipRule="evenodd" fill="rgb(255255,255)" fillRule="evenodd"><path d="m7 2.75c-.41421 0-.75.33579-.75.75v17c0 .4142.33579.75.75.75h10c.4142 0 .75-.3358.75-.75v-17c0-.41421-.3358-.75-.75-.75zm-2.25.75c0-1.24264 1.00736-2.25 2.25-2.25h10c1.2426 0 2.25 1.00736 2.25 2.25v17c0 1.2426-1.0074 2.25-2.25 2.25h-10c-1.24264 0-2.25-1.0074-2.25-2.25z" /><path d="m10.25 5c0-.41421.3358-.75.75-.75h2c.4142 0 .75.33579.75.75s-.3358.75-.75.75h-2c-.4142 0-.75-.33579-.75-.75z" /><path d="m9.25 19c0-.4142.33579-.75.75-.75h4c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-4c-.41421 0-.75-.3358-.75-.75z" /></g></svg>
                                        </i>
                                        <h3>Phone No</h3>
                                        <NavLink to='tel:447459632299'>+44 7459 632299</NavLink>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="location">
                                        <i>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.999 511.999" style={{ enableBackground: 'new 0 0 511.999 511.999' }} xmlSpace="preserve">
                                                <path d="M256.98,40.005c-5.53-0.04-10.04,4.41-10.08,9.93c-0.04,5.52,4.4,10.03,9.93,10.07c5.52,0.04,10.03-4.4,10.07-9.92
                          C266.94,44.565,262.5,40.055,256.98,40.005z" />
                                                <path d="M256.525,100.004c-38.611-0.274-70.232,30.875-70.524,69.474c-0.291,38.597,30.875,70.233,69.474,70.524
                          c0.179,0.001,0.357,0.002,0.536,0.002c38.353,0,69.698-31.056,69.988-69.476C326.29,131.933,295.125,100.295,256.525,100.004z
                           M256.009,220.005c-0.126,0-0.258-0.001-0.384-0.002c-27.571-0.208-49.833-22.806-49.625-50.375
                          c0.207-27.445,22.595-49.627,49.991-49.627c0.126,0,0.258,0.001,0.384,0.002c27.571,0.208,49.833,22.806,49.625,50.375
                          C305.792,197.823,283.405,220.005,256.009,220.005z" />
                                                <path d="M299.631,47.589c-5.202-1.846-10.921,0.879-12.767,6.085c-1.845,5.206,0.88,10.921,6.085,12.767
                          c44.047,15.611,73.402,57.562,73.05,104.389c-0.041,5.522,4.402,10.033,9.925,10.075c0.025,0,0.051,0,0.077,0
                          c5.486,0,9.956-4.428,9.998-9.925C386.415,115.633,351.706,66.046,299.631,47.589z" />
                                                <path d="M317.357,376.442c66.513-85.615,108.08-130.26,108.641-205.164C426.702,77.035,350.22,0,255.984,0
                          C162.848,0,86.71,75.428,86.002,168.728c-0.572,76.935,41.767,121.519,108.739,207.7C128.116,386.384,86.002,411.401,86.002,442
                          c0,20.497,18.946,38.89,53.349,51.79c31.313,11.742,72.74,18.209,116.649,18.209s85.336-6.467,116.649-18.209
                          c34.403-12.901,53.349-31.294,53.349-51.791C425.998,411.417,383.923,386.406,317.357,376.442z M106.001,168.879
                          C106.625,86.55,173.8,20,255.986,20c83.159,0,150.633,67.988,150.013,151.129c-0.532,71.134-44.614,114.971-114.991,206.714
                          c-12.553,16.356-24.081,31.82-34.993,46.947c-10.88-15.136-22.178-30.323-34.919-46.953
                          C147.81,282.253,105.459,241.729,106.001,168.879z M256,492c-85.851,0-149.999-26.397-149.999-50
                          c0-17.504,38.348-39.616,102.826-47.273c14.253,18.701,26.749,35.691,39.005,53.043c1.872,2.65,4.914,4.228,8.159,4.23
                          c0.003,0,0.006,0,0.009,0c3.242,0,6.283-1.572,8.158-4.217c12.14-17.126,24.978-34.535,39.109-53.045
                          c64.418,7.665,102.732,29.77,102.732,47.263C405.998,465.603,341.851,492,256,492z" />
                                            </svg>
                                        </i>
                                        <h3>Address</h3>
                                        <p>Office 4965 321-323 High Road, Chadwell Heath, Essex, United Kingdom, RM6 6AX</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="location mb-0">
                                        <i>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve">
                                                <path d="M485.743,85.333H26.257C11.815,85.333,0,97.148,0,111.589V400.41c0,14.44,11.815,26.257,26.257,26.257h459.487
                          c14.44,0,26.257-11.815,26.257-26.257V111.589C512,97.148,500.185,85.333,485.743,85.333z M475.89,105.024L271.104,258.626
                          c-3.682,2.802-9.334,4.555-15.105,4.529c-5.77,0.026-11.421-1.727-15.104-4.529L36.109,105.024H475.89z M366.5,268.761
                          l111.59,137.847c0.112,0.138,0.249,0.243,0.368,0.368H33.542c0.118-0.131,0.256-0.23,0.368-0.368L145.5,268.761
                          c3.419-4.227,2.771-10.424-1.464-13.851c-4.227-3.419-10.424-2.771-13.844,1.457l-110.5,136.501V117.332l209.394,157.046
                          c7.871,5.862,17.447,8.442,26.912,8.468c9.452-0.02,19.036-2.6,26.912-8.468l209.394-157.046v275.534L381.807,256.367
                          c-3.42-4.227-9.623-4.877-13.844-1.457C363.729,258.329,363.079,264.534,366.5,268.761z" />
                                            </svg>
                                        </i>
                                        <h3>Email Address</h3>
                                        <NavLink to='mailto:info@sendtango.com'>info@sendtango.com</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-80 pl-80">
                                <div className="col-lg-6">
                                    <div className="heading two">
                                        <h6>Contact Us</h6>
                                        <h2>Get In Touch</h2>
                                        <p className="pt-lg-3 pt-md-2">If you need information or support, feel free to contact us</p>
                                    </div>
                                    <h6>Social Networking:</h6>
                                    <ul className="social-icon">
                                        <li><NavLink to="#"><i className="fa-brands fa-facebook-f" /></NavLink></li>
                                        <li><NavLink to="#"><i className="fa-brands fa-google" /></NavLink></li>
                                        <li><NavLink to="#"><i className="fa-brands fa-twitter" /></NavLink></li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <form className="content-form" id="contact-form" onSubmit={handleSubmit}>
                                        <div className='input-field'>
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="Enter Name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {errors.name && <span className="error">{errors.name}</span>}
                                        </div>
                                        <div className='input-field'>
                                            <input
                                                type="text"
                                                name="email"
                                                placeholder="Enter Email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {errors.email && <span className="error">{errors.email}</span>}
                                        </div>
                                        <div className='input-field'>
                                            <input
                                                type="text"
                                                name="phoneno"
                                                placeholder="Enter Phone Number"
                                                value={formData.phoneno}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {errors.phoneno && <span className="error">{errors.phoneno}</span>}
                                        </div>
                                        <div className='input-field'>
                                            <textarea
                                                placeholder="Your Message"
                                                name="msg"
                                                value={formData.msg}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {errors.msg && <span className="error">{errors.msg}</span>}
                                        </div>
                                        <div className='input-field'>
                                            <ReCAPTCHA
                                                sitekey="YOUR_RECAPTCHA_SITE_KEY"
                                                onChange={handleCaptchaChange}
                                            />
                                            {errors.captcha && <span className="error">{errors.captcha}</span>}
                                        </div>
                                        <button type="submit" className="btn">Send Message</button>
                                    </form>
                                </div>
                            </div>
                            <div className="map pt-80">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.397193644977!2d-2.241787322898827!3d53.47923256477178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb10008697b9b%3A0x1aba3c380c145662!2sSt%20James%20Tower!5e0!3m2!1sen!2suk!4v1719559448237!5m2!1sen!2suk" loading="lazy" referrerPolicy="no-referrer-when-downgrade" ></iframe>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            )}
        </div>
    );
}

export default Contact_Us;
